<div>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let row">
            </td>
        </ng-container>

        <!-- Project Column -->
        <ng-container matColumnDef="project">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Project</th>
            <td mat-cell *matCellDef="let element">{{element.project}}</td>
        </ng-container>

        <!-- Software Column -->
        <ng-container matColumnDef="software">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Software</th>
            <td mat-cell *matCellDef="let element">{{element.software}}</td>
        </ng-container>

        <!-- Role Column -->
        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>My role</th>
            <td mat-cell *matCellDef="let element">{{element.role}}</td>
        </ng-container>

        <!-- Manager Column -->
        <ng-container matColumnDef="manager">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created by</th>
            <td mat-cell *matCellDef="let element">{{element.manager}}</td>
        </ng-container>

        <!-- Update Column -->
        <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last update</th>
            <td mat-cell *matCellDef="let element">{{element.update}}</td>
        </ng-container>

        <!-- Updated By Column -->
        <ng-container matColumnDef="updatedBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated by</th>
            <td mat-cell *matCellDef="let element">{{element.updatedBy}}</td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button>
                    <mat-icon svgIcon="download"></mat-icon>
                </button>
                <button mat-icon-button>
                    <mat-icon svgIcon="share"></mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>