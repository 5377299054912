<div class="projects-container">
    <div class="header-actions">
        <div class="flex-center">
            <h2>Projects</h2>
            <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput />
            </mat-form-field>
        </div>

        <span class="toolbar-spacer"></span>

        <button mat-flat-button class="new-project-button">
            <mat-icon>add</mat-icon>
            New Project
        </button>
    </div>
    <app-projects-table></app-projects-table>
</div>