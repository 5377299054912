import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';


interface Project {
  project: string;
  software: string;
  role: string;
  manager: string;
  update: string;
  updatedBy: string;
}
@Component({
  selector: 'app-projects-table',
  templateUrl: './projects-table.component.html',
  styleUrl: './projects-table.component.scss',
})
export class ProjectsTableComponent {
  displayedColumns: string[] = ['select', 'project', 'software', 'role', 'manager', 'update', 'updatedBy', 'actions'];
  dataSource: MatTableDataSource<Project>;

  projects: Project[] = [
    { project: 'Project A', software: 'Software X', role: 'Project Manager', manager: 'Caroline Jones', update: '2024-06-25', updatedBy: 'Caroline Jones' },
    { project: 'Project B', software: 'Software Y', role: 'Developer', manager: 'John Smith', update: '2024-06-24', updatedBy: 'John Smith' },
  ];

  constructor() {
    this.dataSource = new MatTableDataSource(this.projects);
  }
 }
